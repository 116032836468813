import React from 'react'
import { Row, Col, Form } from 'react-bootstrap';

export default function PermissionCheckbox({ permission, clickHandler, employeeData, indent }) {
  return (
    <Row key={permission.permission} className={indent ? 'pl-5' : 'border-bottom'}>
      <Col xs={2}>
        <Form.Check
          id={permission.permission}
          disabled={permission.permission === 'employeeManagement' && true}
          checked={employeeData?.canAccess?.includes(permission.permission)}
          className='mt-2'
          onChange={(e) => {
            clickHandler(e.target.value, e.target.checked);
          }}
          type='checkbox'
          value={permission.permission}
        />
      </Col>
      <Col className='pl-0'>
        <Form.Label for={permission.permission} className='text-body mt-2'>
          {permission.linkName || permission.title}
        </Form.Label>
      </Col>
    </Row>
  )
}
