import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { fields, calculateLineTotal } from './reportFields/reportFields';

export default function RegisterTotalSection({ reportTotals }) {
  return (
    <Col className='p-0'>
      <h2 className='display-6 bg-secondary p-2 text-white'>Subtotal</h2>
      {fields.map((field, idx) => (
        <div key={idx}>
          <Form.Group className='mb-3 d-flex' controlId='registerLineTotals'>
            {field.type === 'text' ? (
              <div style={{ height: '38px' }}></div>
            ) : (
              <Form.Control
                disabled
                type={field.type}
                className={field.className}
                placeholder={field.placeholder}
                value={calculateLineTotal(reportTotals, field)}
              />
            )}
          </Form.Group>
          {field.extraLabels && (
            <div className='d-flex'>
              <Form.Label className='w-100 text-right mt-0 mb-3'>
                <span className='text-danger'>- $300.00</span>
              </Form.Label>
            </div>
          )}
        </div>
      ))}
    </Col>
  );
}
