const cashChangeInDrawer = 100;

const calculateCountedTotal = (totals, register) => {
  const countFields = fields.filter((field) => field.type === 'number' && !field.calculated && !field.fromReceipt);
  let registerTotal = 0;
  countFields.forEach((field) => {
    registerTotal += totals[register]?.[field.id] || 0;
  });
  return (registerTotal - cashChangeInDrawer).toFixed(2);
};

const calculateCountedCash = (totals, register) => (totals[register]?.cashCount - cashChangeInDrawer || 0).toFixed(2);

const calculateTotalDeposit = (totals) => {
  const registers = ['reg1', 'reg2', 'reg3'];
  let subTotal = 0;
  registers.forEach((register) => {
    subTotal += parseFloat(calculateCountedCash(totals, register));
  });
  return subTotal.toFixed(2);
};

const calculateVariance = (totals, register) => {
  return (calculateCountedTotal(totals, register) - (totals[register]?.expectedTotal || 0)).toFixed(2);
};

const calculateGrandTotal = (totals) => {
  const registers = ['reg1', 'reg2', 'reg3'];
  let subTotal = 0;
  registers.forEach((register) => {
    subTotal += parseFloat(calculateCountedTotal(totals, register));
  });

  const grandTotal = subTotal + (totals?.onlineSalesCounted || 0);
  return grandTotal.toFixed(2);
};

const calculateLineTotal = (totals, field) => {
  const registers = ['reg1', 'reg2', 'reg3'];
  let lineTotal = 0;
  registers.forEach((register) => {
    if (field?.calculatedValue) {
      lineTotal += parseFloat(field.calculatedValue(totals, register) || 0);
    } else {
      lineTotal += parseFloat(totals?.[register]?.[field.id] || 0);
    }
  });
  return lineTotal.toFixed(2);
};

const fields = [
  {
    id: 'cashCount',
    label: 'Cash in drawer',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
    extraLabels: true,
  },
  {
    id: 'cashDeposit',
    label: 'Cash for deposit',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
    calculated: true,
    calculatedValue: calculateCountedCash,
  },
  {
    id: 'cashOut',
    label: 'Cash out',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'cashOutReason',
    type: 'text',
    className: '',
    placeholder: 'Enter cash out reason',
  },
  {
    id: 'astro',
    label: 'Astro coupons',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'credit',
    label: 'Credit/debit',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'verifyCredit',
    label: 'Add verified credit',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'giftCard',
    label: 'Gift card',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'loyalty',
    label: 'Loyalty',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'manufacturerCoupon',
    label: 'Mfc. coupons',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'manufacturerCouponDesc',
    type: 'text',
    className: '',
    placeholder: 'List itemized manufacturer coupons',
  },
  {
    id: 'squareTransactions',
    label: 'Square transactions',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'storeUse',
    label: 'Store use',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
  },
  {
    id: 'itemizedStoreUse',
    type: 'text',
    className: '',
    placeholder: 'List itemized store use supplies',
  },
  {
    id: 'countedTotal',
    label: 'Counted total',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
    calculated: true,
    calculatedValue: calculateCountedTotal,
  },
  {
    id: 'expectedTotal',
    label: 'Expected total',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
    fromReceipt: true,
  },
  {
    id: 'variance',
    label: 'Variance',
    type: 'number',
    className: 'text-right',
    placeholder: 0,
    calculated: true,
    calculatedValue: calculateVariance,
  },
];

module.exports = {
  fields,
  calculateCountedTotal,
  calculateCountedCash,
  calculateGrandTotal,
  calculateTotalDeposit,
  calculateLineTotal,
};
