import React, { useState, useContext } from 'react';
import firebase from 'firebase';
import { AuthContext } from '../../../../utils/AuthContext';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';

export default function AddEmployeeModal() {
  const { db } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setLoading(false);
    setShow(true);
  };

  const sendEmployeeRequest = (employeeData) => {
    let data = { ...employeeData, requestSent: firebase.firestore.FieldValue.serverTimestamp() };
    return db
      .collection('employeeAcctReqs')
      .add(data)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.error('Error updating location:', error);
      });
  };

  const handleEmployeeRequest = (employeeData) => {
    if (!validated(employeeData)) {
      alert('Please include first name, last name, and email');
      return;
    }
    setLoading(true);
    sendEmployeeRequest(employeeData).then(() => {
      setShow(false);
      setEmployeeData({});
    });
  };

  const handleDataChange = (field, value) => {
    setEmployeeData({ ...employeeData, [field]: value });
  };

  const validated = (employeeData) => {
    let isValid = false;
    if (employeeData.fName && employeeData.lName && employeeData.email) isValid = true;
    return isValid;
  };

  return (
    <>
      <Button variant='link' className='p-0' onClick={handleShow}>
        Add employee
      </Button>

      <Modal size='xl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send employee invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className='text-body border-bottom'>Details</Form.Label>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Label className='text-body' for='fName'>
                    First name
                  </Form.Label>
                  <Form.Control
                    id='fName'
                    value={employeeData?.fName}
                    onChange={(e) => {
                      handleDataChange('fName', e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label className='text-body' for='lName'>
                    Last name
                  </Form.Label>
                  <Form.Control
                    id='lName'
                    value={employeeData?.lName}
                    onChange={(e) => {
                      handleDataChange('lName', e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label className='text-body' for='email'>
                    Email address
                  </Form.Label>
                  <Form.Control
                    id='email'
                    value={employeeData?.email}
                    onChange={(e) => {
                      handleDataChange('email', e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant='success' onClick={() => handleEmployeeRequest(employeeData)}>
            Send request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
