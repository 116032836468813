import React, { useState, useContext } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import { Helmet } from 'react-helmet-async';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { app } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';

export default function CreateAccount() {
  const { db, updateEmployee } = useContext(AuthContext);
  const [formFields, setFormFields] = useState({});
  const [validationErrors, setvalidationErrors] = useState([]);

  const navigate = useNavigate();

  const handleCreateAccount = async () => {
    setvalidationErrors([]);
    let validation = await checkValidations(formFields);
    setvalidationErrors(validation.errors);
    if (!validation?.isValid) return;
    let userId = await createUser(formFields);
    await updateEmployee(userId, {
      email: formFields.email,
      fName: formFields.fName,
      lName: formFields.lName,
      canAccess: ['employeePortal'],
    });
    navigate('/Employee-Home');
  };

  const createUser = async (formFields) => {
    let userId;
    await app
      .auth()
      .createUserWithEmailAndPassword(formFields.email, formFields.password)
      .then((userCredential) => {
        var user = userCredential.user;
        userId = user.uid;
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
    return userId;
  };

  const checkInviteList = async (email) => {
    let result = { invited: false, id: null };
    await db
      .collection('employeeAcctReqs')
      .where('email', '==', email)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          result.invited = true;
          result.id = doc.id;
        });
      });
    return result;
  };

  const deleteInvite = async (id) => {
    await db.collection('employeeAcctReqs').doc(id).delete();
  };

  const checkValidations = async (formFields) => {
    let isValid = true;
    let errors = [];
    const result = await checkInviteList(formFields.email);
    if (!result.invited) {
      errors.push('This email is not on our invite list');
      isValid = false;
    }
    if (!formFields.fName || !formFields.lName || !formFields.email || !formFields.password) {
      errors.push('Please fill out all required fields');
      isValid = false;
    }
    let emailFormat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (!emailFormat.test(formFields.email)) {
      errors.push('Please enter a valid email address');
      isValid = false;
    }
    if (formFields.password.length < 8) {
      errors.push('Your password must be at least 8 characters in length');
      isValid = false;
    }
    if (formFields.password !== formFields.confirmPassword) {
      errors.push('Your passwords do not match');
      isValid = false;
    }
    if (isValid && result.id) await deleteInvite(result.id);
    return { isValid, errors };
  };

  const handleUpdateFormFields = (field, value) => {
    setFormFields({ ...formFields, [field]: value });
  };

  return (
    <Row>
      <Helmet>
        <title>Create Account | Atlas Pet</title>
      </Helmet>
      <Col sm={8} lg={6} className='ml-auto mr-auto'>
        <Form className='border p-5 rounded shadow'>
          <h1 className='display-4'>Create account</h1>
          <Form.Group className='mb-3' controlId='formFirstname'>
            <Form.Label className='text-dark'>First name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Ex. "John"'
              onChange={(e) => handleUpdateFormFields('fName', e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formLastName'>
            <Form.Label className='text-dark'>Last name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Ex. "Smith"'
              onChange={(e) => handleUpdateFormFields('lName', e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formEmail'>
            <Form.Label className='text-dark'>Email address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Ex. "johnsmith@email.com"'
              onChange={(e) => handleUpdateFormFields('email', e.target.value)}
            />
            <Form.Text className='text-muted'>
              This email address should match the one where you received your invite.
            </Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId='formPassword'>
            <Form.Label className='text-dark'>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Password'
              onChange={(e) => handleUpdateFormFields('password', e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formConfirmPassword'>
            <Form.Label className='text-dark'>Confirm password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm password'
              onChange={(e) => handleUpdateFormFields('confirmPassword', e.target.value)}
            />
          </Form.Group>

          <Row>
            <Col xs={8}>
              {validationErrors.length > 0 && (
                <Alert variant='danger'>
                  {validationErrors.join(', ')}.
                </Alert>
              )}
            </Col>
            <Col className='text-right'>
              <Button variant='primary' type='button' onClick={() => handleCreateAccount()}>
                Create account
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
