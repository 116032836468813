import React, { useState, useContext } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { AuthContext } from '../../../../utils/AuthContext';

export default function EditListingModal({ position }) {
  const { db } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [positionData, setPositionData] = useState({});

  const handleClose = () => {
    setShow(false);
    setPositionData({});
  };
  const handleShow = () => setShow(true);

  const updateListing = (positionId, positionData) => {
    setLoading(true);
    db.collection('employmentPositions')
      .doc(positionId)
      .set(
        {
          ...positionData,
        },
        { merge: true }
      )
      .then(() => {
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <Button variant='primary' onClick={handleShow}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit listing: {position.data.title} - {position.data.location}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className='px-5'
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group className='mb-3' controlId='positionTitle'>
              <Form.Label className='text-dark'>Position title</Form.Label>
              <Form.Control
                type='text'
                defaultValue={position.data.title}
                onChange={(e) => setPositionData({ ...positionData, title: e.target.value })}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='positionDescription'>
              <Form.Label className='text-dark'>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={7}
                defaultValue={position.data.description}
                onChange={(e) => setPositionData({ ...positionData, description: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={() => updateListing(position.id, positionData)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
