import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../utils/AuthContext';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import { routes } from '../../../routing/Routing';
import PermissionCheckbox from './PermissionCheckbox';

export default function EmployeeSettingsModal({ employee }) {
  const [show, setShow] = useState(false);
  const [employeeData, setEmployeeData] = useState(employee.data);

  const { updateEmployee } = useContext(AuthContext);

  const handleEmployeeUpdate = () => {
    updateEmployee(employee.id, employeeData).then(() => {
      setShow(false);
    });
  };

  const handleDataChange = (field, value) => {
    setEmployeeData({ ...employeeData, [field]: value });
  };

  const handlePermissionsChange = (value, checked) => {
    const arr = employeeData?.canAccess || [];
    if (checked && !arr.includes(value)) arr.push(value);
    if (!checked && arr.includes(value)) {
      const index = arr.indexOf(value);
      if (index > -1) arr.splice(index, 1);
    }
    handleDataChange('canAccess', arr);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col className='m-1 px-1'>
      <Button variant='secondary' className='w-100' onClick={handleShow}>
        <img src='./APPIcons/edit.svg'></img>
      </Button>

      <Modal size='xl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Settings: {employee.data.fName} {employee.data.lName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Label className='text-body border-bottom'>Details</Form.Label>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label className='text-body' for='fName'>
                        First name
                      </Form.Label>
                      <Form.Control
                        id='fName'
                        value={employeeData?.fName}
                        onChange={(e) => {
                          handleDataChange('fName', e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label className='text-body' for='lName'>
                        Last name
                      </Form.Label>
                      <Form.Control
                        id='lName'
                        value={employeeData?.lName}
                        onChange={(e) => {
                          handleDataChange('lName', e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label className='text-body' for='email'>
                        Email address (login username)
                      </Form.Label>
                      <Form.Control id='email' disabled value={employeeData?.email} />
                    </Col>
                    <Col>
                      <Form.Label className='text-body'>Location</Form.Label>
                      <Form.Control
                        as='select'
                        value={employeeData?.location}
                        onChange={(e) => {
                          handleDataChange('location', e.target.value);
                        }}
                      >
                        <option>Assign location</option>
                        <option>All</option>
                        <option>Blaine</option>
                        <option>Stillwater</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Label className='text-body border-bottom'>Permissions</Form.Label>
                <Form.Group controlId='formBasicCheckbox'>
                  {routes.map((permission, idx) => (
                    <>
                      <PermissionCheckbox permission={permission} clickHandler={handlePermissionsChange} employeeData={employeeData} />
                      {permission?.subPermissions && permission.subPermissions.map((subPermission) => (<PermissionCheckbox permission={subPermission} clickHandler={handlePermissionsChange} employeeData={employeeData} indent={true}/>))}
                    </>
                  ))}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleEmployeeUpdate()}>Save</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}
