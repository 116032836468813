import React from 'react';
import { ListGroup, Row, Col, Form } from 'react-bootstrap';
import EditListingModal from './EditListingModal';

export default function PositionGroup({ location, positions, onChange }) {
  return (
    <ListGroup className='w-100'>
      <ListGroup.Item>
        <Row>
          <Col>
            <strong>{location}</strong>
          </Col>
          <Col xs={3} className='text-center'>
            Available
          </Col>
          <Col className='text-right'>Make changes</Col>
        </Row>
      </ListGroup.Item>
      {positions.map((position, idx) => (
        <ListGroup.Item key={idx}>
          <Form>
            <Row>
              <Col>
                {position.data.title} - {position.data.classification}
              </Col>
              <Col xs={3} className='text-center'>
                <Form.Check
                  value={position.id}
                  checked={position.data.available}
                  onChange={(e) => {
                    onChange(e.target.value, e.target.checked);
                  }}
                  type='checkbox'
                />
              </Col>
              <Col className='text-right'>
                <EditListingModal position={position}/>
              </Col>
            </Row>
          </Form>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
