import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import EmployeeHome from '../../pages/employee/EmployeeHome';
import EmployeeLogin from '../../pages/employee/EmployeeLogin';
import ApplicationViewer from '../employee/applicationViewer/ApplicationViewer';
import CourseCreator from '../employee/courseCreator/CourseCreator';
import EmployeeEditor from '../employee/employeeManagement/EmployeeEditor';
import CareerManagement from '../employee/careerManagement/CareerManagement';
import Careers from '../../pages/careers/Careers';
import EmployeeNav from '../employee/EmployeeNav';
import CreateAccount from '../employee/CreateAccount';
import ResetPassword from '../../pages/employee/ResetPassword';
import SalesTotals from '../employee/saleTotals/SaleTotals';

const routes = [
  {
    linkName: 'Training',
    path: '/Employee-Home',
    permission: 'employeePortal',
    component: <EmployeeHome permission='employeePortal' />,
  },
  {
    linkName: 'Sale Totals',
    path: '/Employee-SalesTotals',
    permission: 'salesTotals',
    component: <SalesTotals permission='salesTotals' />,
    subPermissions: [
      {
        permission: 'salesTotalsAdmin',
        title: 'Sales Admin'
      }
    ]
  },
  {
    linkName: 'View Applications',
    path: '/Employee-ApplicationViewer',
    permission: 'viewApplications',
    component: <ApplicationViewer permission='viewApplications' />,
  },
  {
    linkName: 'Course Creator',
    path: '/Employee-CourseCreator',
    permission: 'courseCreator',
    component: <CourseCreator permission='courseCreator' />,
  },
  {
    linkName: 'Career Management',
    path: '/Career-Management',
    permission: 'careerManagement',
    component: <CareerManagement permission='careerManagement' />,
  },
  {
    linkName: 'Employee Management',
    path: '/Employee-Management',
    permission: 'employeeManagement',
    component: <EmployeeEditor permission='employeeManagement' />,
  },
];

const filteredRoutes = (permissionArr) => routes.filter((route) => permissionArr.includes(route.permission));

const Routing = () => {
  return (
    <Routes>
      <Route exact path='/' element={<EmployeeLogin />} />
      <Route exact path='/Reset-Password' element={<ResetPassword />} />
      <Route exact path='/Careers' element={<Careers />} />
      <Route exact path='/Create-Account' element={<CreateAccount />} />
      {routes.map((route, idx) => (
        <Route
          key={idx}
          exact
          path={route.path}
          element={
            <>
              <Helmet>
                <title>{`${route.linkName} | Atlas Pet`}</title>
              </Helmet>
              <EmployeeNav />
              {route.component}
            </>
          }
        />
      ))}
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export { routes, filteredRoutes, Routing };
